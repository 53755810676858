// Gatsby supports TypeScript natively!
import React from "react"
import { PageProps, Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const Lodging = (props: PageProps) => (
  <Layout>
    <SEO title="places to stay" />
    <Link to="/">
      <div className="pt-10 md:pt-20">
    <p className="text-gray-600">⬅ Go back</p>
    <h1 className="text-3xl mb-3 mt-3">places to stay</h1></div></Link>
    <h2 className="font-semibold text-3xl mb-3 mt-14" style={{fontFamily:"bad script"}}>South Congress Hotel</h2>    
    <p className="text-lg font-medium">~$329/ night <span className="text-sm text-gray-600"> 0.0 miles from South Congress Hotel</span></p>
    <button className="mt-4 mb-4 bg-white hover:bg-gray-300 text-black font-bold py-2 px-4 rounded-full"><a href="https://be.synxis.com/?adult=1&arrive=2022-02-11&chain=19403&child=0&currency=USD&depart=2022-02-12&group=Bubley-Kellgren&hotel=76402&level=hotel&locale=en-US&rooms=1">book it</a></button>
    <h2 className="font-semibold text-3xl mb-3 mt-3" style={{fontFamily:"bad script"}}>Extended Stay America - Downtown Austin Townlake</h2>    
    <p className="text-lg font-medium">~$138/ night <span className="text-sm text-gray-600"> 1.0 miles from South Congress Hotel</span></p>
    <button className="mt-4 mb-4 bg-white hover:bg-gray-300 text-black font-bold py-2 px-4 rounded-full"><a href="https://www.extendedstayamerica.com/hotels/tx/austin/downtown">book it</a></button>

    <h2 className="font-semibold text-3xl mb-3 mt-3" style={{fontFamily:"bad script"}}>Hyatt Regency Austin</h2>
    <p className="text-lg font-medium">~$288/ night<span className="text-sm text-gray-600"> 0.9 miles from South Congress Hotel</span></p>
    <button className="mt-4 mb-4 bg-white hover:bg-gray-300 text-black font-bold py-2 px-4 rounded-full"><a href="https://www.hyatt.com/en-US/hotel/texas/hyatt-regency-austin/ausra">book it</a></button>
    <h2 className="font-semibold text-3xl mb-3 mt-3" style={{fontFamily:"bad script"}}>Embassy Suites Hilton - Downtown Austin South Congress</h2>   
    <p className="text-lg font-medium">~$250/night<span className="text-sm text-gray-600">   0.9 miles from South Congress Hotel</span></p>
    <button className="mt-4 mb-4 bg-white hover:bg-gray-300 text-black  font-bold py-2 px-4 rounded-full"><a href="https://www.hilton.com/en/hotels/auslkes-embassy-suites-austin-downtown-south-congress/">book it</a></button>
    <h2 className="font-semibold text-3xl mb-3 mt-3" style={{fontFamily:"bad script"}}>Aiden by Best Western</h2>    
    <p className="text-lg font-medium">~$209/ night<span className="text-sm text-gray-600">  1.5 miles from South Congress Hotel</span></p>
    <button className="mt-4 mb-4 bg-white hover:bg-gray-300 text-black  font-bold py-2 px-4 rounded-full"><a href="https://austincityhotel.com/">book it</a></button>

  </Layout>
)

export default Lodging
